import React from 'react';
import { cloneDeep } from 'lodash';

export class TextArea extends React.Component {

    /**
     * Constructor
     */
    constructor(props) {
    
        super(props);

        // Initial state.
        this.state = {

        };

        // Create a ref to store the DOM element.
        this.elementRef = React.createRef();
    };

    // Render.
    render() {
        return (<>
        <textarea 
                id={this.props.question.identifier}
                name={this.props.question.identifier}
                rows={(this.props.question.value || '').split('\n').length || 1}
                style={{ 
                    width: '100%',
                    borderRadius: '10px',
                    minHeight: '50px',
                    border: '1px solid rgb(100, 100, 100)',
                    overflow: 'hidden',
                    resize: 'none',
                    marginTop: '8px'
                }}
                value={this.props.question.value || ''}
                onChange={(event) => {
                    let updatedQuestion =cloneDeep(this.props.question);
                    updatedQuestion.value = event.target.value;
                    updatedQuestion.touched = true;
                    this.props.updateQuestion(updatedQuestion, this.props.questionnaireIndex, this.props.questionIndex);
                }}>
            </textarea>

        </>

        )
    };

}