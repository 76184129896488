import React from 'react';
import { cloneDeep } from 'lodash';

export class DateTime extends React.Component {

    /**
     * Constructor
     */
    constructor(props) {
    
        super(props);

        // Initial state.
        this.state = {
            isValidDate: true
        };

        // Create a ref to store the DOM element.
        this.elementRef = React.createRef();
    };

    validate = () => {

        setTimeout(() => {
            const date = new Date(this.props.question.year, this.props.question.month - 1, this.props.question.day);
            const isValid = date.getFullYear() == this.props.question.year &&
                            date.getMonth() == this.props.question.month - 1 &&
                            date.getDate() == this.props.question.day;
        
            this.setState({
                isValidDate: isValid
            })
        }, 10);

    };

    // Render.
    render() {
        return (
            <>
            
                {/* Label */}
                <label
                    htmlFor={this.props.question.identifier}>
                    {this.props.question.label}
                </label>

                {/* Description */}
                <div style={{ 
                        paddingTop: '6px',
                        paddingBottom: '6px',
                        fontSize: '11px',
                        color: '#808080',
                        whiteSpace: 'pre-line'
                    }}>
                    {this.props.question.description}
                </div>

                <div
                    style={{
                        display: 'flex',
                        marginTop: '20px'
                    }}>

                    {/* Day */}
                    <div
                        style={{
                            display: 'block'
                        }}>
                    
                        <div>
                            <label>
                                Day
                            </label>
                        </div>

                        <input 
                            id={this.props.question.identifier}
                            name={this.props.question.identifier}
                            type='number'
                            max={31}
                            maxLength={2}
                            placeholder='DD'
                            style={{ 
                                width: '90px',
                                borderRadius: '10px',
                                height: '50px',
                                border: '1px solid rgb(100, 100, 100)',
                                marginTop: '8px',
                                marginRight: '10px'
                            }}
                            value={this.props.question.day ?? ''}
                            onChange={(event) => {
                                let updatedQuestion = cloneDeep(this.props.question);
                                updatedQuestion.day = event.target.value;

                                if (event.target.value != null && updatedQuestion.month != null && updatedQuestion.year != null) {
                                    updatedQuestion.value = event.target.value + '/' + updatedQuestion.month + '/' + updatedQuestion.year;
                                    this.validate();
                                } else {
                                    updatedQuestion.value = null;
                                }

                                this.props.updateQuestion(updatedQuestion, this.props.questionnaireIndex, this.props.questionIndex)
                            }} />

                    </div>


                    {/* Month */}
                    <div
                        style={{
                            display: 'block'
                        }}>
                    
                        <div>
                            <label>
                                Month
                            </label>
                        </div>

                        <input 
                            id={this.props.question.identifier}
                            name={this.props.question.identifier}
                            type='number'
                            max={12}
                            maxLength={2}
                            placeholder='MM'
                            style={{ 
                                width: '90px',
                                borderRadius: '10px',
                                height: '50px',
                                border: '1px solid rgb(100, 100, 100)',
                                marginRight: '10px',
                                marginTop: '8px',
                            }}
                            value={this.props.question.month ?? ''}
                            onChange={(event) => {
                                let updatedQuestion = cloneDeep(this.props.question);
                                updatedQuestion.month = event.target.value;

                                if (updatedQuestion.day != null && event.target.value != null && updatedQuestion.year != null) {
                                    updatedQuestion.value = updatedQuestion.day + '/' + event.target.value + '/' + updatedQuestion.year;
                                    this.validate();
                                } else {
                                    updatedQuestion.value = null;
                                }

                                this.props.updateQuestion(updatedQuestion, this.props.questionnaireIndex, this.props.questionIndex)
                            }} />
                    </div>

                    {/* Year */}
                    <div
                        style={{
                            display: 'block'
                        }}>
                    
                        <div>
                            <label>
                                Year
                            </label>
                        </div>
                        
                        <input 
                            id={this.props.question.identifier}
                            name={this.props.question.identifier}
                            type='number'
                            maxLength={4}
                            placeholder='YYYY'
                            style={{ 
                                width: '140px',
                                borderRadius: '10px',
                                height: '50px',
                                border: '1px solid rgb(100, 100, 100)',
                                marginTop: '8px',
                            }}
                            value={this.props.question.year ?? ''}
                            onChange={(event) => {
                                let updatedQuestion = cloneDeep(this.props.question);
                                updatedQuestion.year = event.target.value;

                                if (updatedQuestion.day != null && updatedQuestion.month != null && event.target.value != null) {
                                    updatedQuestion.value = updatedQuestion.day + '/' + updatedQuestion.month + '/' + event.target.value;
                                    this.validate();
                                } else {
                                    updatedQuestion.value = null;
                                }

                                this.props.updateQuestion(updatedQuestion, this.props.questionnaireIndex, this.props.questionIndex)
                            }} />

                    </div>

                </div>

                {!this.state.isValidDate && (
                    <div style={{
                        color: 'red',
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                        </svg>
                        <span style={{ marginLeft: '.5rem' }}>Invalid date</span>
                    </div>
                )}

            </>
        )
    };

}