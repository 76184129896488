import React from 'react';
import { ShopifyService } from './service/shopify.service';
import { cloneDeep } from 'lodash';
import { SelectQuestion } from './components/select-question';
import { DateTime } from './components/date-time';
import { TextArea } from './components/text-area';

export class App extends React.Component {

    /**
     * Constructor
     */
    constructor(props) {

        super(props);

        // Initial state.
        this.state = {
            questionnairesToShow: null,
            showLightbox: false,
            questionnairesToShowIndex: 0,
            imageSrc: null,
            formSubmitted: false
        };

        // Bind functions.
        this.handleAddToCart = this.handleAddToCart.bind(this);
        this.submit = this.submit.bind(this);
        this.updateQuestion = this.updateQuestion.bind(this);
    };

    /**
     * Component mounted.
     */
    componentDidMount() {
        try {

            // Validate.
            if (questionnaires == null) { return; }
            if (productId == null) { return; }
            if (productHandle == null) { return; }

            // On 'add button' listener - Button name.
            const addButton = document.querySelectorAll('button[name="add"]');
            for (var i = 0; i < addButton.length; i++) {
                addButton[i].addEventListener('click', (event) => this.handleAddToCart(event));
            }

            // Get the product image.
            ShopifyService.getProduct(productHandle).then((response) => {
                if (response.featured_image) {
                    this.setState({
                        imageSrc: 'https:' + response.featured_image,
                    });
                }
            }, (error) => {
                console.log(error);    
            });

            // Get the questionnaires to show.
            let questionnairesToShow = []
            questionnaires.forEach(questionnaire => {        
                if (questionnaire.productIds.indexOf(productId) != -1) {
                    // let cleanString = '[' + questionnaire.questions.replace(/=>/g, ':').replace(/}{/g, '},{') + ']';
                    // try {
                    //     questionnairesToShow = JSON.parse(cleanString);
                    // } catch (e) {
                    //     console.error("Parsing error:", e);
                    // }
                    // questionnaire.questions = JSON.parse('[' + questionnaire.questions.replaceAll('=>', ':').replaceAll('}{', '},{') + ']');
                    questionnairesToShow.push(questionnaire);                  
                }
            });

            // Set the questionnaires to show.
            this.setState({
                questionnairesToShow: questionnairesToShow,
                questionnairesToShowIndex: 0
            });

        } catch (error) {
            console.log(error);
        }
    };

    /**
     * Handle add to cart.
     * @param {*} event the event.
     */
    handleAddToCart(event) {
        try {

            if (this.state.questionnairesToShow == null || this.state.questionnairesToShow.length == 0 || this.state.showLightbox) {
                return;
            }

            // Stop the default event.
            event.stopPropagation();
            event.preventDefault();

            // Show the lightbox
            this.setState({
                showLightbox: true
            });

        } catch (error) {
            console.log(error);
        }
    };

    /**
     * Questionnaite submit.
     * @param {*} event the event.
     */
    submit(event) {

        // Stop the default event.
        event.stopPropagation();
        event.preventDefault();

        // Set the form has been submitted.
        this.setState({
            formSubmitted: true
        });

        // Validate.
        let valid = true;
        this.state.questionnairesToShow[this.state.questionnairesToShowIndex].questions.forEach(question => {
            if (question.value == null || question.value == '') {
                valid = false;
            }

            if (question.type == 'Date picker') {

                const date = new Date(question.year, question.month - 1, question.day);
                const isValidDate = date.getFullYear() == question.year && date.getMonth() == question.month - 1 && date.getDate() == question.day;
                if (!isValidDate) {
                    valid = false;
                }
                
            }

            if (question.type == 'Single select' || question.type == 'Multi select') {
                question.choices.forEach((choice) => {
                    if (choice.freeText == true) {
                        const freeTextIndex = question.value?.indexOf(choice.value)
                        if (freeTextIndex > -1) {
                            if (question.freeText == null || question.freeText == '') {
                                valid = false;
                            }
                        }
                    }
                });
            }

        });
        if (!valid) {
            return;
        }

        if (this.state.questionnairesToShowIndex + 1 < this.state.questionnairesToShow.length) {

            // Go to the next quesionnaire
            this.setState({
                questionnairesToShowIndex: this.state.questionnairesToShowIndex + 1
            });
            
        } else {

            // Get the form.
            let form = document.querySelectorAll('[action="/cart/add"]')[document.querySelectorAll('[action="/cart/add"]').length - 1];

            // Flagged.
            // let flagged = [];
            this.state.questionnairesToShow.forEach(questionnaire => {
                questionnaire.questions.forEach(question => {
                    var propName = question.identifier;
                    if (question.type == 'Single select' || question.type == 'Multi select') {
                        question.choices.forEach((choice) => {
                            if (choice.flag == true) {
                                const flagIndex = question.value.indexOf(choice.value)
                                if (flagIndex > -1) {
                                    // Add the input property.
                                    let input = document.createElement("input");
                                    input.setAttribute("type", "hidden");
                                    input.setAttribute("name", "properties[_" + "!!!FLAGGED!!!_" + propName + "]");
                                    input.setAttribute("value", question.value.substr(flagIndex, choice.value.length));
                                    form.appendChild(input);
                                }
                            }
                        });
                    }
                });
            });

            // Set the properties.
            this.state.questionnairesToShow.forEach(questionnaire => {
                questionnaire.questions.forEach(question => {

                    // Get the prop name.
                    var propName = question.identifier;
                    if (!propName.startsWith('_')) {
                        propName = '_' + propName;
                    }

                    // Add the input property.
                    let input = document.createElement("input");
                    input.setAttribute("type", "hidden");
                    input.setAttribute("name", "properties[" + propName + "]");

                    let value = question.value;
                    if (question.type == 'Single select' || question.type == 'Multi select') {
                        question.choices.forEach((choice) => {
                            if (choice.freeText == true) {
                                const freeTextIndex = question.value?.indexOf(choice.value)
                                if (freeTextIndex > -1) {
                                    value += ' ' + question.freeText;     
                                }
                            }
                        });
                    }

                    input.setAttribute("value", value);
                    form.appendChild(input);
                });
            });

            this.setState({
                showLightbox: false
            })

            // Add to cart.
            let addButton = document.querySelectorAll('button[name="add"]')[document.querySelectorAll('button[name="add"]').length - 1];
            addButton.click();
        }
    };

    /**
     * Updates a question. 
     * @param {*} updatedQuestion the updated question.
     * @param {*} questionnaireIndex the questionnaire index.
     * @param {*} questionIndex the question index.
     */
    updateQuestion(updatedQuestion, questionnaireIndex, questionIndex) {
        this.setState({
            questionnairesToShow: this.state.questionnairesToShow.map((_questionnaire, questionnaireI) => {
                if (questionnaireI === questionnaireIndex) {
                    _questionnaire.questions = _questionnaire.questions.map((_question, questionI) => {         
                        if (questionI == questionIndex) {
                            return updatedQuestion;
                        }
                        return _question;
                    });
                }
                return _questionnaire;
            })
        });
    };
 
    // Render.
    render() {
        return (
            <>

                {/* Questionnaires */}
                {this.state.questionnairesToShow?.map((questionnaire, questionnaireIndex) => ( 
                    <div key={questionnaireIndex}>
                        {this.state.questionnairesToShowIndex == questionnaireIndex ?
                            <>

                                {/* Dark overlay */}
                                {this.state.showLightbox ?
                                    <div style={{ 
                                        background: 'rgb(0 0 0 / 0.45)',
                                        position: 'fixed',
                                        bottom: '0',
                                        right: '0',
                                        width: '100vw',
                                        height: '100vh',
                                        zIndex: '9999',
                                        animation: 'dark-overlay-fade-in .5s both',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>

                                        {/* Lightbox */}
                                        <form
                                            className='lightbox lightbox-fade-in'
                                            style={{
                                                position: 'relative',
                                                background: 'white',
                                                // borderRadius: '16px',
                                                boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
                                            }}
                                            onSubmit={this.submit}>

                                            {/* Close */}
                                            <div style={{
                                                    position: 'absolute',
                                                    right: 0,
                                                    top: 0,
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {

                                                    // Hide the lightbox.
                                                    this.setState({
                                                        showLightbox: false
                                                    });

                                                }}>
                                                <div style={{
                                                    paddingRight: '15px',
                                                    paddingTop: '10px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    fontSize: '12px'
                                                }}>
                                                    <div>Close</div> 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </div>
                                            </div>

                                            {/* Heading */}
                                            <div style={{
                                                    paddingLeft: '30px',
                                                    paddingRight: '30px',
                                                    textAlign: 'center',
                                                }}>
                                                <h1
                                                    className='product__title'
                                                    style={{
                                                        fontSize: '28px',
                                                        paddingTop: '28px'
                                                    }}>{questionnaire.heading || "Questions from our Pharmacist"}
                                                </h1>
                                                <p>{questionnaire.description || "As this is a Pharmacy Medicine, we need to ask you a few questions. These will help our Pharmacist ensure the product is right for you."}</p>
                                            </div>
                    
                                            <div
                                                style={{
                                                    borderBottom: '1px solid rgb(200, 200, 200)',
                                                    paddingLeft: '30px',
                                                    paddingRight: '30px',
                                                    paddingBottom: '30px',
                                                    textAlign: 'center',
                                                }}>

                                                {/* Product image */}
                                                {this.state.imageSrc != null ?
                                                    <img 
                                                        style={{
                                                            marginRight: '1rem',
                                                            borderRadius: '.5rem',
                                                            border: '1px solid #d3d3d3',
                                                            marginBottom: '6px'
                                                        }}
                                                        src={this.state.imageSrc}
                                                        height={80}
                                                        width={80} />
                                                : null}

                                                {/* Product title */}
                                                {productTitle != null ?
                                                    <div>{productTitle}</div>
                                                : null}

                                            </div>
                                        
                                            {/* Questions */}
                                            {questionnaire.questions.map((question, questionIndex) => ( 
                                                <div key={questionIndex}>
                                                    
                                                    <fieldset>

                                                        {/* ********* */}
                                                        {/* Free text */}
                                                        {/* ********* */}
                                                        {question.type == 'Free text' ?
                                                            <>
                                                                
                                                                {/* Label */}
                                                                <label
                                                                    htmlFor={question.identifier}>
                                                                    {question.label}
                                                                </label>

                                                                {/* Description */}
                                                                <div style={{ 
                                                                        paddingTop: '6px',
                                                                        paddingBottom: '6px',
                                                                        fontSize: '11px',
                                                                        color: '#808080',
                                                                        whiteSpace: 'pre-line'
                                                                    }}>
                                                                    {question.description}
                                                                </div>

                                                                {/* Text area */}
                                                                <TextArea 
                                                                    questionnaireIndex={questionnaireIndex}
                                                                    question={question}
                                                                    questionIndex={questionIndex}
                                                                    multiple={true}
                                                                    updateQuestion={this.updateQuestion} />

                                                            </>
                                                        : null}

                                                        {/* ********* */}
                                                        {/* Number    */}
                                                        {/* ********* */}
                                                        {question.type == 'Number' ?
                                                            <>
                                                                
                                                                {/* Label */}
                                                                <label
                                                                    htmlFor={question.identifier}>
                                                                    {question.label}
                                                                </label>

                                                                {/* Description */}
                                                                   <div style={{ 
                                                                        paddingTop: '6px',
                                                                        paddingBottom: '6px',
                                                                        fontSize: '11px',
                                                                        color: '#808080',
                                                                        whiteSpace: 'pre-line'
                                                                    }}>
                                                                    {question.description}
                                                                </div>

                                                                {/* Value */}
                                                                <input 
                                                                    id={question.identifier}
                                                                    name={question.identifier}
                                                                    type='number'
                                                                    rows="5"
                                                                    style={{ 
                                                                        width: '100%',
                                                                        borderRadius: '10px',
                                                                        marginTop: '8px',
                                                                        height: '50px',
                                                                        border: '1px solid rgb(100, 100, 100)'
                                                                    }}
                                                                    value={question.value || ''}
                                                                    onChange={(event) => {
                                                                        let updatedQuestion = cloneDeep(question);
                                                                        updatedQuestion.value = event.target.value;
                                                                        updatedQuestion.touched = true;
                                                                        this.updateQuestion(updatedQuestion, questionnaireIndex, questionIndex)
                                                                    }} />

                                                            </>
                                                        : null}
                                                        
                                                        {/* ********* */}
                                                        {/* Timespan  */}
                                                        {/* ********* */}
                                                        {question.type == 'Timespan' ?
                                                            <>

                                                                {/* Label */}
                                                                <div>
                                                                    <label
                                                                        htmlFor={question.identifier}>
                                                                        {question.label}
                                                                    </label>
                                                                </div>

                                                                {/* Description */}
                                                                <div style={{ 
                                                                        paddingTop: '6px',
                                                                        paddingBottom: '6px',
                                                                        fontSize: '11px',
                                                                        color: '#808080',
                                                                        whiteSpace: 'pre-line'
                                                                    }}>
                                                                    {question.description}
                                                                </div>

                                                                <div
                                                                    style={{
                                                                        display: 'flex'
                                                                    }}>

                                                                        {/* Years */}
                                                                        <div style={{
                                                                            marginRight: '20px'
                                                                        }}>

                                                                        {/* Label */}
                                                                        <label
                                                                            htmlFor={question.identifier}>
                                                                            Years
                                                                        </label>

                                                                            {/* Value */}
                                                                            <input 
                                                                                id={question.identifier}
                                                                                name={question.identifier}
                                                                                type='number'
                                                                                rows="5"
                                                                                style={{ 
                                                                                    width: '100%',
                                                                                    borderRadius: '10px',
                                                                                    height: '50px',
                                                                                    border: '1px solid rgb(100, 100, 100)',
                                                                                    marginTop: '8px'
                                                                                }}
                                                                                value={question.years || ''}
                                                                                onChange={(event) => {
                                                                                    let updatedQuestion = cloneDeep(question);
                                                                                    updatedQuestion.years = event.target.value;
                                                                                    updatedQuestion.value = ((event.target.value== null || event.target.value == '') ? '0' : event.target.value) + ' Years and ' + ((updatedQuestion.months == null || updatedQuestion.months == '') ? '0' : updatedQuestion.months)  + ' months';
                                                                                    updatedQuestion.touched = true;
                                                                                    this.updateQuestion(updatedQuestion, questionnaireIndex, questionIndex)
                                                                                }} />
                                                                        </div>
                                                                    
                                                                    {/* Months */}
                                                                    <div style={{
                                                                    }}>
                                                                    
                                                                        {/* Label */}
                                                                        <label
                                                                            htmlFor={question.identifier}>
                                                                            Months
                                                                        </label>

                                                                        {/* Value */}
                                                                        <input 
                                                                            id={question.identifier}
                                                                            name={question.identifier}
                                                                            type='text'
                                                                            maxLength={2}
                                                                            rows="5"
                                                                            style={{ 
                                                                                width: '100%',
                                                                                borderRadius: '10px',
                                                                                height: '50px',
                                                                                border: '1px solid rgb(100, 100, 100)',
                                                                                marginTop: '8px'
                                                                            }}
                                                                            value={question.months || ''}
                                                                            onChange={(event) => {
                                                                                let updatedQuestion = cloneDeep(question);
                                                                                updatedQuestion.months = event.target.value;
                                                                                updatedQuestion.value = ((updatedQuestion.years == null || updatedQuestion.years == '')  ? '0' : updatedQuestion.years) + ' Years and ' + ((event.target.value== null || event.target.value == '') ? '0' : event.target.value) + ' months';
                                                                                updatedQuestion.touched = true;
                                                                                this.updateQuestion(updatedQuestion, questionnaireIndex, questionIndex)
                                                                            }} />

                                                                    
                                                                    </div>
                                                                </div>

                                                            </>
                                                        : null}

                                                        {/* ********* */}
                                                        {/* Single select */}
                                                        {/* ********* */}
                                                        {question.type == 'Single select' ?
                                                            <SelectQuestion
                                                                questionnaireIndex={questionnaireIndex}
                                                                question={question}
                                                                questionIndex={questionIndex}
                                                                updateQuestion={this.updateQuestion} />
                                                        : null}

                                                        {/* ********* */}
                                                        {/* Multi select */}
                                                        {/* ********* */}
                                                        {question.type == 'Multi select' ?
                                                            <SelectQuestion 
                                                                questionnaireIndex={questionnaireIndex}
                                                                question={question}
                                                                questionIndex={questionIndex}
                                                                multiple={true}
                                                                updateQuestion={this.updateQuestion} />
                                                        : null}
                                                        
                                                        {/* ********* */}
                                                        {/* Date picker */}
                                                        {/* ********* */}
                                                        {question.type == 'Date picker' ?
                                                            <DateTime 
                                                                questionnaireIndex={questionnaireIndex}
                                                                question={question}
                                                                questionIndex={questionIndex}
                                                                updateQuestion={this.updateQuestion} />
                                                        : null}

                                                        {/* Invalid label */}
                                                        {(question.value == null || question.value == '' || question.value == '0 Years and 0 months') && (question.touched == true || this.state.formSubmitted) ?
                                                            <div
                                                                style={{
                                                                    paddingTop: question.type != 'Free text' ? '.8rem' : '0'
                                                                }}>
                                                                <div style={{
                                                                    color: 'red',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }}>
                                                                    <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                                                    </svg>
                                                                    <span style={{ marginLeft: '.5rem' }}>This field is mandatory</span>
                                                                </div>
                                                            </div>
                                                        : null}

                                                    </fieldset>

                                                </div>
                                            ))}

                                            <div style={{
                                                // borderTop: '1px solid rgb(200, 200, 200)',
                                                width: '100%',
                                                paddingTop: '28px',
                                                paddingBottom: '8px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                  
                                                {/* Submit */}
                                                <button
                                                    style={{
                                                        border: 'none',
                                                        paddingTop: '10px',
                                                        paddingBottom: '10px',
                                                        background: 'black',
                                                        paddingLeft: '10px',
                                                        width: '260px',
                                                        color: 'white',
                                                        fontSize: '12px',
                                                        borderRadius: '30px',
                                                        textTransform: 'uppercase'
                                                    }}>
                                                        Submit
                                                </button>

                                            </div>

                                            <div style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                paddingBottom: '16px'
                                            }}>
                                  
                                                {/* Cancel */}
                                                <button
                                                    type="button"
                                                    style={{
                                                        border: 'none',
                                                        paddingTop: '10px',
                                                        paddingBottom: '10px',
                                                        background: 'white',
                                                        paddingLeft: '10px',
                                                        width: '260px',
                                                        fontSize: '12px',
                                                        textTransform: 'uppercase'
                                                    }}
                                                    onClick={() => {

                                                        // Hide the lightbox.
                                                        this.setState({
                                                            showLightbox: false
                                                        });
    
                                                    }}>
                                                        Cancel
                                                </button>

                                            </div>

                                        </form>
                                    
                                    </div>
                                : null}
                            
                            </>
                        : null}
                    </div>
                ))}

            </>
        );
    };
};