import React from 'react';
import { cloneDeep } from 'lodash';

export class SelectQuestion extends React.Component {

    /**
     * Constructor
     */
    constructor(props) {
    
        super(props);

        // Initial state.
        this.state = {

        };

        // Create a ref to store the DOM element.
        this.elementRef = React.createRef();
    };

    /**
     * Component mounted.
     */
    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
    };
    
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    };

    handleClickOutside = (event) => {
        if (this.elementRef.current && !this.elementRef.current.contains(event.target)) {
            
            // Hide the menu.
            let updatedQuestion = cloneDeep(this.props.question);
            updatedQuestion.showMenu = false;
            this.props.updateQuestion(updatedQuestion, this.props.questionnaireIndex, this.props.questionIndex);

        }
    };

    parseCommaSeparatedString = (str) => {
        const values = [];
        let current = '';
        let inQuotes = false;
        let prevChar = null;
    
        for (let char of str) {
            if (char === '"' && prevChar !== '\\') {
                inQuotes = !inQuotes;
            } else if (char === ',' && !inQuotes) {
                values.push(current);
                current = '';
            } else {
                if (!(char === '\\' && prevChar === '\\')) {
                    current += char;
                }
            }
            prevChar = char;
        }
        values.push(current); // Add last value
    
        return values.map(val => val.replace(/\\"/g, '"'));
    }

    // Function to parse the comma-separated string
    parseCommaSeparatedString = (str) => {
        const values = [];
        let current = '';
        let inQuotes = false;
        let prevChar = null;

        for (let char of str) {
            if (char === '"' && prevChar !== '\\') {
                inQuotes = !inQuotes;
            } else if (char === ',' && !inQuotes) {
                values.push(current);
                current = '';
            } else {
                if (!(char === '\\' && prevChar === '\\')) {
                    current += char;
                }
            }
            prevChar = char;
        }
        values.push(current); // Add last value

        return values.map(val => val.replace(/\\"/g, '"').replace(/^"|"$/g, ''));
    }

    removeValueFromString = (str, valueToRemove) => {

        // Function to construct a comma-separated string without enclosing quotes
        function constructCommaSeparatedString(values) {
            return values.join(',');
        }

        // Parse the input string, remove the value, and reconstruct the string
        const values = this.parseCommaSeparatedString(str);
        const filteredValues = values.filter(val => val !== valueToRemove);
        return constructCommaSeparatedString(filteredValues);
    }   

    getValuesAsArray = () => {
        if (this.props.question.value) {
            return this.props.question.value.split(',');
        }
        return [];
    }

    freeText = () => {
        let freeText = false;
        this.props.question.choices.forEach((choice) => {
            if (choice.freeText == true) {
                const freeTextIndex = this.props.question.value?.indexOf(choice.value)
                if (freeTextIndex > -1) {
                    freeText = true;
                }
            }
        });
        return freeText;
    }

    // Render.
    render() {
        return (
            <>
            
                {/* Label */}
                <div>
                    <label
                        htmlFor={this.props.question.identifier}>
                        {this.props.question.label}
                    </label>
                </div>

                {/* Description */}
                <div style={{ 
                        paddingTop: '6px',
                        paddingBottom: '6px',
                        fontSize: '11px',
                        color: '#808080',
                        whiteSpace: 'pre-line'
                    }}>
                    {this.props.question.description}
                </div>

                {/* <div 
                    ref={this.elementRef}
                    style={{
                        position: 'relative',
                        width: '100%',
                        borderRadius: '10px',
                        height: '50px',
                        marginTop: '8px',
                        border: '1px solid rgb(100, 100, 100)',
                    }}
                    onClick={() => {

                        // Show the menu.
                        let updatedQuestion = cloneDeep(this.props.question);
                        updatedQuestion.showMenu = true;
                        this.props.updateQuestion(updatedQuestion, this.props.questionnaireIndex, this.props.questionIndex);
                
                    }}>
                            
                    Value
                    <div
                        style={{
                            position: 'absolute',
                            padding: '10px',
                            width: '100%',
                            height: '50px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                        
                            {this.props.multiple ? 
                                <>
                                    <div>Choose options to add</div>
                                </>
                            :
                                <>
                                    <div>{this.props.question.value == null ? 'Choose an option' : this.props.question.value}</div>  
                                </>
                            }
                                <svg 
                                    style={{
                                        marginLeft: 'auto'
                                    }}
                                
                                    width={20} height={20} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                </svg>
                    </div>
                    
                    Drop down
                    {this.props.question.showMenu ?     
                        <div 
                            style={{
                                position: 'absolute',
                                width: 'calc(100% + 2px)',
                                borderRadius: '10px',
                                border: '1px solid rgb(100, 100, 100)',
                                background: 'white',
                                marginLeft: '-1px',
                                marginTop: '48px',
                                overflow: 'hidden',
                                zIndex: '99'
                            }}>

                                Choices
                                {this.props.question.choices?.map((choice, choiceIndex) => (
                                    <div 
                                        key={choiceIndex}
                                        className='choice'
                                        style={{
                                            padding: '10px',
                                            background: this.props.question.value?.indexOf(choice.value) > -1 ? 'rgb(242, 242, 242)' : ''
                                        }}
                                        onClick={(event) => {

                                            event.stopPropagation();

                                            let updatedQuestion = cloneDeep(this.props.question);

                                            // Set the value.
                                            if (this.props.multiple) {

                                                if (updatedQuestion.value) {
                                                    const index = updatedQuestion.value.indexOf(choice.value)
                                                    if (index == -1) {
                                                        updatedQuestion.value += ',' + this.parseCommaSeparatedString(choice.value);
                                                    } else {
                                                        updatedQuestion.value = this.removeValueFromString(updatedQuestion.value, choice.value)
                                                    }   
                                                } else {
                                                    updatedQuestion.value = this.parseCommaSeparatedString(choice.value)[0];
                                                }
                                                
                                            } else {
                                                updatedQuestion.value = choice.value;
                                            }

                                            // Show the menu.
                                            updatedQuestion.showMenu = false;
                                            this.props.updateQuestion(updatedQuestion, this.props.questionnaireIndex, this.props.questionIndex);

                                        }}>
                                        {choice.value}
                                    </div>
                                ))}

                        </div>
                    : null}

                </div> */}

                {/* Selected values */}
                {/* {this.props.multiple ?
                    <>
                        {this.getValuesAsArray().map((value, valueIndex) => (
                            <div 
                            key={valueIndex}
                            style={{
                                padding: '5px'
                            }}>
                                {value}
                            </div>
                        ))}
                    </>
                : null} */}

                {this.props.question.choices?.map((choice, choiceIndex) => (
                    <label className="custom-radio-button" key={choiceIndex}>
                        <input 
                            style={{
                                marginLeft: this.props.multiple ? "-15px" : '0px'
                                // marginLeft: '-8px'
                            }}
                            type={ this.props.multiple ? "checkbox" : 'radio'}
                            className="custom-radio-button__input"
                            value={choice.value}
                            checked={this.props.question?.value != null ? this.props.question.value.indexOf(choice.value) != -1 : false}
                            onChange={(event) => {
    
                                event.stopPropagation();
    
                                let updatedQuestion = cloneDeep(this.props.question);
    
                                // Set the value.
                                if (this.props.multiple) {
    
                                    if (updatedQuestion.value) {
                                        const index = updatedQuestion.value.indexOf(choice.value)
                                        if (index == -1) {
                                            updatedQuestion.value += ',' + this.parseCommaSeparatedString(choice.value);
                                        } else {
                                            updatedQuestion.value = this.removeValueFromString(updatedQuestion.value, choice.value)
                                        }   
                                    } else {
                                        updatedQuestion.value = this.parseCommaSeparatedString(choice.value)[0];
                                    }
                                    
                                } else {
                                    updatedQuestion.value = choice.value;
                                }
    
                                // Show the menu.
                                updatedQuestion.showMenu = false;
                                this.props.updateQuestion(updatedQuestion, this.props.questionnaireIndex, this.props.questionIndex);
    
                            }}
                        />
                        <span className="custom-radio-button__control"></span>
                        {choice.value}
                    </label>
                ))}
            

         
                {/* {this.props.question.choices?.map((choice, choiceIndex) => (
        
                    <label 
                        key={choiceIndex}
                        style={{
                            display: 'block',
                            marginBottom: '8px'
                        }}>
                        {choice.value}
                        <input
                            type={ this.props.multiple ? "checkbox" : 'radio'}
                            value={choice.value}
                            checked={this.props.question?.value != null ? this.props.question.value.indexOf(choice.value) != -1 : false}
                            onChange={(event) => {

                                event.stopPropagation();

                                let updatedQuestion = cloneDeep(this.props.question);

                                // Set the value.
                                if (this.props.multiple) {

                                    if (updatedQuestion.value) {
                                        const index = updatedQuestion.value.indexOf(choice.value)
                                        if (index == -1) {
                                            updatedQuestion.value += ',' + this.parseCommaSeparatedString(choice.value);
                                        } else {
                                            updatedQuestion.value = this.removeValueFromString(updatedQuestion.value, choice.value)
                                        }   
                                    } else {
                                        updatedQuestion.value = this.parseCommaSeparatedString(choice.value)[0];
                                    }
                                    
                                } else {
                                    updatedQuestion.value = choice.value;
                                }

                                // Show the menu.
                                updatedQuestion.showMenu = false;
                                this.props.updateQuestion(updatedQuestion, this.props.questionnaireIndex, this.props.questionIndex);

                            }}
                        />
                    </label>
                
                ))} */}
   
                {this.freeText() ?
                    <>
                        {/* Label */}
                        <label
                            htmlFor={this.props.question.identifier + '-freeText'}>
                            Details
                        </label>

                        {/* Text area */}
                        <textarea 
                            id={this.props.question.identifier + '-freeText'}
                            name={this.props.question.identifier + '-freeText'}
                            rows="5"
                            style={{ 
                                width: '100%',
                            }}
                            value={this.props.question.freeText || ''}
                            onChange={(event) => {
                                let updatedQuestion = cloneDeep(this.props.question);
                                updatedQuestion.freeText = event.target.value;
                                this.props.updateQuestion(updatedQuestion, this.props.questionnaireIndex, this.props.questionIndex)
                            }}>
                        </textarea>
                    </>
                : null}

            </>
        )
    };

}