/**
 * Shopify service
 */
export const ShopifyService = {

    // Gets a product.
    getProduct: function(productHandle) {
        return new Promise((resolve, reject) => {
            fetch(window.Shopify.routes.root + 'products/' + productHandle + '.js', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            })
            .then(function(response) {
                return response.json();
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        });
    },

    // Adds a product to the cart.
    addToCart: function(variantId, properties) {
        return new Promise((resolve, reject) => {
            let body = {
                items: [{
                    id: variantId,
                    quantity: 1
                }]
            };
            if (properties != null) {
                body.items[0].properties = properties;
            }
            fetch(window.Shopify.routes.root + 'cart/add.js', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            .then(response => {
                return response.json();
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        });
    },

    // Adds a product to the cart.
    updateQuantity: function(variantId, qty) {
        return new Promise((resolve, reject) => {
            fetch(window.Shopify.routes.root + 'cart/update.js', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'updates': {
                        [variantId]: qty
                    }
                })
            })
            .then(response => {
                return response.json();
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        });
    },

    // Gets the users cart.
    getCart: function() {
        return new Promise((resolve, reject) => {
            fetch(window.Shopify.routes.root + 'cart.js', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            })
            .then(function(response) {
                return response.json();
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        });
    },

};